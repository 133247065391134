import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import TagManager from 'react-gtm-module'
import './index.css';
 
const tagManagerArgs = {
    gtmId: 'GTM-5RQ72QG2'
}
 
TagManager.initialize(tagManagerArgs)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
