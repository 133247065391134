import { useEffect } from "react";

const delay = ms => new Promise(res => setTimeout(res, ms));

function App() {

  useEffect(()=>{
    const runRedirect = async () => {
      await delay(3000);
      window.location.replace("https://wa.me/5491121771132/");
    };
    runRedirect()
  }
  ,[]);

  return (
    <div className="grid h-screen w-full m-auto items-center justify-items-center">      
        <img src="/logo.png" className="w-1/2	loading" alt="logo" />
    </div>
  );
}

export default App;
